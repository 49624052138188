* {
  padding: 0px;
  margin: 0px;
}

body {
  padding: 10px;
  margin: 10px;
}

.product-text {
  color: black;
}

.buy-btn {
  background-color: aquamarine;
  border: none;
  border-radius: 8px;
  font-size: large;
  padding: 10px;
  margin: 10px;
}

.container {
  display: flex;
  flex-direction: row;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px;
}